import { Container } from '@lib/utils/stateful'
import Stoor from 'stoor'

const COUNTRY_NAMESPACE_KEY = 'country'
const ACTIVE_COUNTRY_KEY = 'active-country'

const DEFAULT_COUNTRY_STATE = {
  countries: require('@lib/config/countries.json')
}

class CountryContainer extends Container {
  constructor () {
    super()

    this.countryStorage = new Stoor({ namespace: COUNTRY_NAMESPACE_KEY })

    const { countries } = this.countryStorage.get(
      ACTIVE_COUNTRY_KEY,
      DEFAULT_COUNTRY_STATE
    )

    this.state = {
      countries
    }
  }

  getCountries () {
    return this.state.countries.map(current => ({
      label: current.name.common,
      value: current.cca2
    }))
  }

  getRegions (countryCode = '') {
    const regions = this.getByCountryCode(countryCode).stateProvinces.map(
      ({ name }) => ({ label: name, value: name })
    )

    return regions
  }

  getCountriesWithProvinces () {
    return this.state.countries.map(
      ({ name, cca2: value, stateProvinces }) => ({
        label: name.common,
        value,
        provinces:
          stateProvinces &&
          stateProvinces.map(({ name: provName }) => ({
            label: provName,
            value: provName
          }))
      })
    )
  }

  getByCountryCode (countryCode) {
    const country = this.state.countries.filter(
      current => current.cca2 === countryCode
    )[0]

    if (!country) {
      return { label: countryCode, value: countryCode, stateProvinces: [] }
    }

    return {
      label: country.name.common,
      value: country.cca2,
      stateProvinces: country.stateProvinces
    }
  }
}

const country = new CountryContainer()

export default country
